import { GlowClientType } from "@luma-team/shared";
import { addGlowIdDict } from "@glow/react/components/glow-id/GlowIdProvider";
import config from "../config";
import { GlowClientConstructor } from "@glow/react/utils/GlowClientConstructor";

export const ZmClient = new GlowClientConstructor(config.apiUrl, {
  clientType: GlowClientType.GlowWeb,
  onResponse: (resp) => {
    addGlowIdDict(resp as any);
  },
});
