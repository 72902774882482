import { GlowClient } from "@glow-xyz/glow-client";
import { GlowIdTypes, Solana } from "@luma-team/shared";
import { useOnMount } from "@lux/atoms/hooks/useOnMount";
import { usePolling } from "@lux/atoms/hooks/usePolling";
import { createUseAppContext } from "@utils/context";
import { callWithToast } from "@utils/toast";
import { ZmClient } from "@utils/ZmClient";
import React, { createContext, useCallback, useState } from "react";

type User = {
  address: Solana.Address;
  glowId: GlowIdTypes.BasicInfo | null;
};

type Context = {
  user: User | null;

  signIn: () => Promise<void>;
  signOut: () => Promise<void>;

  canSignIn: boolean;
};

export const UserContext = createContext<Context | null>(null);

export const glowClient = new GlowClient();

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);

  const [canSignIn, setCanSignIn] = useState(false);

  usePolling(() => {
    if (window.glow) {
      setCanSignIn(true);
    }
  }, 250);

  useOnMount(() => {
    glowClient.on("update", () => {
      setUser(
        glowClient.address
          ? {
              address: glowClient.address,
              // We don't support Glow ID in "update" yet.
              glowId: null,
            }
          : null,
      );
    });
  });

  const signIn = useCallback(async () => {
    await callWithToast(
      async () => {
        if (!window.glow) {
          return;
        }

        const {
          address,
          signatureBase64,
          message,
          signedTransactionBase64,
          glowId,
        } = await window.glow.signIn();

        // This sets the cookie for future requests (we use this for admins)
        await ZmClient.post("/glow/sign-in", {
          wallet: address,
          signed_transaction_base64: signedTransactionBase64,
          signature: signatureBase64,
          message,
          app: "glow-app",
        });

        setUser({
          address,
          glowId,
        });
      },
      {
        loading: "Signing in...",
        success: "Signed in with Glow.",
        error: "Error signing in with Glow.",
      },
    );
  }, [setUser]);

  const signOut = useCallback(async () => {
    await window.glow!.signOut();
    setUser(null);
  }, [setUser]);

  return (
    <UserContext.Provider
      value={{
        user,
        canSignIn,
        signIn,
        signOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = createUseAppContext(UserContext);
