import { Solana } from "@luma-team/shared";
import FormFunctionIcon from "../../icons/Formfn.svg";
import FractalIcon from "../../icons/Fractal.svg";
import HyperspaceIcon from "../../icons/Hyperspace.svg";
import MagicEdenIcon from "../../icons/MagicEden.svg";
import MarketplaceBaseIcon from "../../icons/Marketplace16.svg";
import OpenSeaIcon from "../../icons/OpenSea.svg";
import SolanartIcon from "../../icons/Solanart.svg";
import YawwwIcon from "../../icons/Yawww.svg";
import React from "react";

export const MarketplaceIcon = ({
  className,
  marketplace,
  size = "1rem",
}: {
  className?: string;
  marketplace: Solana.Marketplace | null;
  size?: string | number;
}) => {
  const props = {
    className,
    style: {
      width: size,
      height: size,
    },
  };

  if (marketplace === Solana.Marketplace.OpenSea) {
    return <OpenSeaIcon {...props} />;
  }
  if (marketplace === Solana.Marketplace.MagicEden) {
    return <MagicEdenIcon {...props} />;
  }
  if (marketplace === Solana.Marketplace.Fractal) {
    return <FractalIcon {...props} />;
  }
  if (marketplace === Solana.Marketplace.FormFunction) {
    return <FormFunctionIcon {...props} />;
  }
  if (marketplace === Solana.Marketplace.Solanart) {
    return <SolanartIcon {...props} />;
  }
  if (marketplace === Solana.Marketplace.Hyperspace) {
    return <HyperspaceIcon {...props} />;
  }
  if (marketplace === Solana.Marketplace.Yawww) {
    return <YawwwIcon {...props} />;
  }

  return <MarketplaceBaseIcon {...props} />;
};
