export const GLOW_HEADER_RELEASE_VERSION = "x-glow-release-version"; // eg. 1.2
export const GLOW_HEADER_BUILD_VERSION = "x-glow-build-version"; // eg. 29

export const GLOW_HEADER_CLIENT_TYPE = "x-luma-client-type"; // "glow-chrome-extension" | "glow-ios-app" | "luma-ios-com.luma.studio";
export const GLOW_HEADER_CLIENT_VERSION = "x-luma-client-version"; // eg. 1.0.0

export enum GlowClientType {
  GlowIOS = "glow-ios-app",
  GlowAndroid = "glow-android-app",
  GlowDesktopExtension = "glow-desktop-extension",
  GlowMobileExtension = "glow-mobile-extension",
  GlowWeb = "glow-web",
}
