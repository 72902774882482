import { LuxLink } from "@lux/atoms/components/LuxLink";
import GlowLogoIcon from "@glow/react/icons/GlowLogo.svg";
import TwitterIcon from "@lux/icons/brands/Twitter.svg";
import classNames from "classnames";
import React from "react";

import { ResponsiveBreakpoint } from "@utils/StyleConstants";

export type FooterProps = {
  border?: boolean;
};

export const Footer = ({ border = true }: FooterProps) => {
  return (
    <footer className={classNames("footer", { border })}>
      <div className="footer-inner flex spread">
        <div className="col left flex">
          <div className="logo">
            <GlowLogoIcon />
          </div>
        </div>
        <div className="col right">
          <nav>
            <ul className="flex-center m-0 p-0 flex-wrap">
              <li>
                <LuxLink href="https://docs.glow.app">Docs</LuxLink>
              </li>
              <li>
                <LuxLink href="/support">Support</LuxLink>
              </li>
              <li>
                <LuxLink href="/terms">Terms</LuxLink>
              </li>
              <li>
                <LuxLink href="/privacy-policy">Privacy</LuxLink>
              </li>
              <li className="desktop-only">
                <LuxLink href="/bug-bounty">Bug Bounty</LuxLink>
              </li>
              <li className="twitter">
                <LuxLink href="https://twitter.com/glowwallet" openInNewTab>
                  <TwitterIcon />
                </LuxLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <style jsx>{`
        .footer {
          width: 100%;
          position: relative;
          padding: 0 var(--side-padding);

          &.border {
            margin-top: 2rem;

            .footer-inner {
              border-top: 1px solid var(--divider-color);
            }
          }

          @media (max-width: ${ResponsiveBreakpoint.medium}) {
            margin-top: 3rem;
          }

          .footer-inner {
            padding: 1.5rem 0;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              border-top: 1px solid var(--divider-color);
            }
          }

          .col {
            position: relative;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              flex-direction: column;
              justify-content: space-between;
            }
          }

          .logo {
            color: var(--tertiary-color);

            :global(svg) {
              width: 50px;
              height: auto;
              display: block;
            }

            @media (max-width: ${ResponsiveBreakpoint.tiny}) {
              :global(svg) {
                width: 40px;
              }
            }
          }

          nav {
            ul {
              list-style: none;
              gap: 1rem;

              @media (max-width: ${ResponsiveBreakpoint.small}) {
                gap: 0.75rem;
              }

              @media (max-width: ${ResponsiveBreakpoint.tiny}) {
                gap: 0.625rem;
              }

              li {
                list-style: none;
                display: block;

                @media (max-width: ${ResponsiveBreakpoint.small}) {
                  &.desktop-only {
                    display: none;
                  }
                }

                :global(a) {
                  color: var(--tertiary-color);
                }

                :global(a:hover) {
                  color: var(--brand-color);
                }

                &.twitter {
                  margin-top: -2px;
                }
              }

              @media (max-width: ${ResponsiveBreakpoint.medium}) {
                justify-content: flex-end;
              }

              @media (max-width: ${ResponsiveBreakpoint.tiny}) {
                margin-top: -2px !important;

                li {
                  font-size: var(--font-size-sm);
                  white-space: nowrap;
                }
              }
            }
          }
        }
      `}</style>
    </footer>
  );
};
