import axios, { AxiosError, Cancel } from "axios";
import toast from "react-hot-toast";
import {
  DEFAULT_TOAST_STYLE,
  ERROR_TOAST_STYLE,
  SUCCESS_TOAST_STYLE,
} from "@lux/atoms/utils/toast";
import { isNetworkError as isErrorNetworkError } from "@lux/atoms/utils/error";

type ValueFunction<TValue, TArg> = (arg: TArg) => TValue;
type ValueOrFunction<TValue, TArg> = TValue | ValueFunction<TValue, TArg>;

const isFunction = <TValue, TArg>(
  valOrFunction: ValueOrFunction<TValue, TArg>,
): valOrFunction is ValueFunction<TValue, TArg> =>
  typeof valOrFunction === "function";

const resolveValueOrFunction = <TValue, TArg>(
  valOrFunction: ValueOrFunction<TValue, TArg>,
  arg: TArg,
): TValue => (isFunction(valOrFunction) ? valOrFunction(arg) : valOrFunction);

export const createCallWithToast = () => {
  /**
   * Call an API endpoint with toast notification and error reporting.
   *
   * @param call A promise to be awaited or a function that generates a promise.
   * @param msgs Messages for loading, success and error. If no loading message
   *   specified, then the toast is not shown in loading state.
   */
  const callWithToast = async <T>(
    call: Promise<T> | (() => Promise<T>),
    msgs: {
      loading?: string;
      success?: ValueOrFunction<string, T>;
      error: ValueOrFunction<string, any>;
    },
  ): Promise<
    | { data: null; error: Error | Cancel | AxiosError }
    | { data: T; error: null }
  > => {
    let toastId = undefined;
    if (msgs.loading) {
      toastId = toast.loading(msgs.loading, DEFAULT_TOAST_STYLE);
    }

    try {
      let value;
      if (typeof call === "function") {
        value = await call();
      } else {
        value = await call;
      }

      if (msgs.success) {
        toast.success(resolveValueOrFunction(msgs.success, value), {
          id: toastId,
          ...SUCCESS_TOAST_STYLE,
        });
      } else if (toastId) {
        // Remove the loading toast
        toast.dismiss(toastId);
      }

      return { data: value, error: null };
    } catch (error) {
      // Handle the special Axios cancel case.
      if (axios.isCancel(error)) {
        toast.dismiss(toastId);
        return { data: null, error: error as Cancel };
      }

      // Figure out if this is a network error
      // We don't want to send network errors to Sentry because there isn't much we can do about them
      // and they are really noisy.
      const isNetworkError = isErrorNetworkError(error);

      console.error(error);

      let toastMessage = resolveValueOrFunction(msgs.error, error);

      if (isNetworkError) {
        toastMessage =
          "Ouch - looks like there's a network error. Please check your internet connection and try again.";
      } else if (error) {
        // If the server response specifies a client message, we show that
        const anyError = error as any;
        toastMessage =
          anyError?.response?.data?.message ||
          anyError?.message ||
          toastMessage;

        toastMessage = toastMessage.trim();
      }

      toast.error(toastMessage, {
        id: toastId,
        ...ERROR_TOAST_STYLE,
      });

      return { data: null, error: error as Error };
    }
  };

  return callWithToast;
};
