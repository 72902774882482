/**
 * Truncate a long string of words so that we return whole words that do not exceed maxLength
 *
 * Edge case: if the first word is longer than maxLength, we return it
 *
 * @param text
 * @param maxLength
 */
export const truncateTextByWord = ({
  text,
  maxLength,
}: {
  text: string | null;
  maxLength: number;
}): string => {
  if (!text) {
    return "";
  }

  const [firstWord, ...otherWords] = text.split(" ");

  if (!firstWord) {
    return text;
  }

  let currLength = firstWord.length;

  const outputWords = [firstWord];
  for (const word of otherWords) {
    currLength += word.length;
    if (currLength > maxLength) {
      outputWords[outputWords.length - 1] =
        outputWords[outputWords.length - 1] + "...";
      break;
    }

    outputWords.push(word);
  }

  return outputWords.join(" ");
};

// special cases that can't be handled just by tacking on an `s`
const PLURAL_WORDS: Record<string, string> = {
  person: "people",
  Person: "People",
};

/**
 * Pluralizes a word, depending on whether a number is 1.  (e.g., "1 credit",
 * "2 credits")
 *
 * @param word
 * @param num
 */
export const pluralize = (word: string, num: number) => {
  if (num === 1 || num === -1) {
    return word;
  }
  return PLURAL_WORDS[word] || `${word}s`;
};

/**
 * Adapted from https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 *
 * Keep in sync with ios/String.swift
 */
export const stringHash = (str: string, mod: number): number => {
  if (!str) {
    return 0;
  }

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return Math.abs(hash % mod);
};
