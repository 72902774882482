import { useEffect, useRef } from "react";
import { useOnMount } from "./useOnMount";
import { Duration, DurationLike } from "luxon";

// From here https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// If intervalMs is null, then we will not run the loop
export const usePolling = (
  callback: () => void,
  interval: number | DurationLike | null,
  { runOnMount }: { runOnMount: boolean } = { runOnMount: false },
) => {
  const savedCallback = useRef<() => void>(() => null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useOnMount(() => {
    if (runOnMount) {
      callback();
    }
  });

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (interval != null) {
      const intervalMs =
        typeof interval === "number"
          ? interval
          : Duration.fromObject(interval).as("milliseconds");
      const id = setInterval(tick, intervalMs);
      return () => clearInterval(id);
    }

    return undefined;
  }, [interval]);
};
