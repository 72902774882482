export enum GlowColor {
  Black = "black",
  Gray = "gray",
  Cranberry = "cranberry",
  Barney = "barney",
  Red = "red",
  Green = "green",
  Blue = "blue",
  Purple = "purple",
  Yellow = "yellow",
  Orange = "orange",
}

export type GlowColorWithoutGray = Exclude<
  GlowColor,
  GlowColor.Gray | GlowColor.Black
>;

export namespace Colors {
  export const PRIMARY_BG_COLOR = "#ffffff";
  export const SECONDARY_BG_COLOR = "#f4f5f6";

  export const DARK_PRIMARY_BG_COLOR = "#131517";
  export const DARK_SECONDARY_BG_COLOR = "#212325";
}
